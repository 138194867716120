<template>
  <div class="overlay-loading">
    <!-- <img :src="loading_spinner" width="60px" alt=""> -->
    <div
      class="loader loader--style6"
      title="5"
      style="width: 60px; height: 60px"
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="https://www.w3.org/2000/svg"
        xmlns:xlink="https://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="72px"
        height="90px"
        viewBox="0 0 24 30"
        style="enable-background: new 0 0 50 50"
        xml:space="preserve"
      >
        <rect x="0" y="13" width="4" height="5" fill="#333">
          <animate
            attributeName="height"
            attributeType="XML"
            values="5;21;5"
            begin="0s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            attributeType="XML"
            values="13; 5; 13"
            begin="0s"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="10" y="13" width="4" height="5" fill="#333">
          <animate
            attributeName="height"
            attributeType="XML"
            values="5;21;5"
            begin="0.15s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            attributeType="XML"
            values="13; 5; 13"
            begin="0.15s"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="20" y="13" width="4" height="5" fill="#333">
          <animate
            attributeName="height"
            attributeType="XML"
            values="5;21;5"
            begin="0.3s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            attributeType="XML"
            values="13; 5; 13"
            begin="0.3s"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </rect>
      </svg>
    </div>
  </div>
</template>


<script>
import loading_spinner from "../assets/global_assets/images/loading.gif";
export default {
  name: "BaseOverlay",
  data() {
    return {
      loading_spinner: loading_spinner,
    };
  },
};
</script>

<style lang="scss" scoped>
.overlay-loading {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
</style>
