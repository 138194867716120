import http from '../modules/http.index'

const state = () => ({
  showSidebar: false,
  showSidebarMobile: false,
  sidebarMenu: []

});
const getters =  {
};
const mutations= {
  _toggleSidebar(state) {
    state.showSidebar = !state.showSidebar
  },
  _toggleSidebarMobile(state) {
    console.log("state", state.showSidebar);
    state.showSidebar = !state.showSidebar
    state.showSidebarMobile = !state.showSidebarMobile
    if (state.showSidebarMobile) {
      document.body.classList.add("sidebar-mobile-main")
    } else {
      document.body.classList.remove("sidebar-mobile-main")
    }
  },
  setSidebarMenu: (state, payload) => state.sidebarMenu = payload

};
const actions = {
  // toggle sidebar
  // expand sidebar to full screen on mobile
  // toggle main sidebar on mobile

  // sidebar menu
  async getSidebarMenu(ctx) {
    return new Promise((resolve, reject) => {
      http.get('/orders/OrderStats/').then((res) => {
        if (res.status === 200) {
          ctx.commit('setSidebarMenu', res.data.Data);
          resolve(res)
        } else reject(res)
      })
        .catch((err) => reject(err))
    })
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
