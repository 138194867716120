import http from '../modules/http.index'
import Form from 'vform'
import objectToFormData from "../assets/js/objectToFormData"
const baseUrl = process.env.VUE_APP_API_BASE_URL
const state = () => ({
  priceUrl: `${baseUrl}/orders/CalculatePrice/`,
  orderUrl: `${baseUrl}/orders/PlaceOrder/`,
  updateOrderUrl: `${baseUrl}/orders/Edit/`,
  orderExtrasUrl: `${baseUrl}/orders/AddExtra/`,
  orderUpdateUrl: `${baseUrl}/orders/update/`,
  fileUploadUrl: `${baseUrl}/orders/Upload/`,
  changeStatusUrl: `${baseUrl}/orders/UpdateStatus/`,
  updateWriterPrefsUrl: `${baseUrl}/orders/UpdateWriterPreference/`,
  price: {},
  order: {},
  loading: false,
  orderForm: new Form({
    order_id: "",
    academic_level: "",
    subject_area: "",
    urgency: "",
    document_type: "",
    spacing: "",
    writer_level: "",
    additional_services: [],
    pages: 0,
    charts: 0,
    slides: 0,
    files: [],
  }),

  writerLevelForm: new Form({
    order_id: -1,
    writer_level: -1,
  }),

  orderExtraForm: new Form({
    order_id: "",
    additional_services: [],
  }),

  deadline: '',

  statusForm: new Form({
    status: '',
    message: '',
    order_id: '',
  }),
  coupon: "",
  message: []
})

const getters = {
  getPrice: (state) => {
    return state.price
  },
   totalPrice: (state) => {
    let price = state.price.find((item) => item.title == "Total");
    return price

  }
  // setDeadline: (state, payload) => state.deadline = payload,

}

const mutations = {

  setPrice: (state, payload) => {
    state.price = payload.Data.totals
    state.message = payload.Data.message
  },
  setOrder: (state, payload) => {
    state.order = payload
    state.deadline = "Loading"
    // init order form
    state.orderForm.order_id = payload.order_id
    state.orderForm.academic_level = payload.academic_level
    state.orderForm.subject_area = payload.subject_area
    state.orderForm.urgency = payload.urgency
    state.orderForm.document_type = payload.document_type
    state.orderForm.spacing = payload.spacing
    state.orderForm.writer_level = payload.writer_level
    state.orderForm.additional_services = payload.additional_services
    // state.orderForm.pages = payload.pages
    // state.orderForm.charts = payload.charts
    // state.orderForm.slides = payload.slides
    state.orderExtraForm.additional_services = payload.additional_services.map(item => item[0])
    state.writerLevelForm.writer_level = payload.writer_level
  },



  unsetOrder: (state) => {
    state.order = {}
    state.price = {}
    state.deadline = "Loading"
  },

  _setUploadFiles: (state, payload) => {
    state.orderForm.files = payload
  },

  updatePages(state, option) {
    if (!option) {
      if (state.orderForm.pages > 0) {
        state.orderForm.pages--
      }
    } else {
      if (state.orderForm.pages <= 1000) {
        state.orderForm.pages++
      }
    }

    // this.calculatePrice()
  },



  updateSlides(state, option) {
    if (!option) {
      if (state.orderForm.slides > 0) {
        state.orderForm.slides--
      }
    } else {
      state.orderForm.slides++
    }
    // state.calculatePrice()
  },

  updateCharts(state, option) {
    if (!option) {
      if (state.orderForm.charts > 0) {
        state.orderForm.charts--
      }
    } else {
      state.orderForm.charts++
    }
    // state.calculatePrice()
  },

  setCoupon(state, payload) { state.coupon = payload },

}


const actions = {



  async _calculatePrice(ctx, payload) {
    return new Promise((resolve, reject) => {
      console.log("calculating price: ", ctx.order);
      payload
        .post(ctx.state.priceUrl)
        .then((res) => {
          if (res.status === 200) {
            ctx.commit("setPrice", res.data);
            resolve(res);
          }
          else reject(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async _getCoupon(ctx) {
    return new Promise((resolve, reject) => {
      console.log("calculating price: ", ctx.order);
      http
        .get('/orders/Coupons/')
        .then((res) => {
          console.log("coupons", res);
          if (res.status === 200) {
            ctx.commit("setCoupon", res.data.Data.message);
            resolve(res);
          }
          else reject(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async _placeOrder(ctx, payload) {
    return new Promise((resolve, reject) => {
      payload
        .post(ctx.state.orderUrl, {
          transformRequest: [function (data) {
            return objectToFormData(data)
          }]
        })
        .then((res) => {
          // if (res.status === 200) {
          resolve(res);
          // }
          // else reject(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async _updateOrder(ctx, payload) {
    return new Promise((resolve, reject) => {
      payload
        .put(`${ctx.state.updateOrderUrl + payload.id}`)
        .then((res) => {
          // if (res.status === 200) {
          resolve(res);
          // }
          // else reject(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async addOrderExtras(ctx, payload) {
    return new Promise((resolve, reject) => {
      ctx.state.loading = true
      payload
        .post(ctx.state.orderExtrasUrl)
        .then((res) => {
          ctx.dispatch("_getOrder", payload.order_id)
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => ctx.state.loading = false)
    });
  },



  async _getOrder(ctx, id) {
    return new Promise((resolve, reject) => {
      http
        .get(`/orders/Get/${id}`)
        .then(res => {
          if (res.status === 200) {
            console.log("order", res);
            ctx.commit("setOrder", res.data.Data);
            resolve(res);
          } else reject(res);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          ctx.commit("isLoading", false, { root: true })
        })
    })
  },

  async updateWriterPreferences(ctx, payload) {
    return new Promise((resolve, reject) => {
      ctx.state.loading = true;
      payload.order_id = ctx.state.order.id
      payload
        .post(ctx.state.updateWriterPrefsUrl)
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch("_getOrder", payload.order_id)
            payload.reset()
            resolve(res)
          } else reject(res)
        })
        .catch((err) => reject(err))
        .finally(() => ctx.state.loading = false)
    })
  },



  async updateOrder(ctx, payload) {
    return new Promise((resolve, reject) => {
      ctx.state.loading = true;
      payload.order_id = ctx.state.order.id
      payload
        .post(ctx.state.orderUpdateUrl)
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch("_getOrder", payload.order_id)
            payload.reset()
            resolve(res)
          } else reject(res)
        })
        .catch((err) => reject(err))
        .finally(() => ctx.state.loading = false)
    })
  },

  async _uploadFiles(ctx, payload) {
    return new Promise((resolve, reject) => {
      ctx.state.loading = true;
      payload
        .post(ctx.state.fileUploadUrl + ctx.state.order.id, {
          transformRequest: [function (data) {
            return objectToFormData(data)
          }]

        })
        .then(res => {
          if (res.status === 200) {
            resolve(res)
            ctx.dispatch("_getOrder", ctx.state.order.id)
            ctx.commit("_setUploadFiles", [])
          } else reject(res)
        })
        .catch((err) => reject(err))
        .finally(() => ctx.state.loading = false)
    })
  },

  async _downloadFile(ctx, payload) {
    return new Promise((resolve, reject) => {
      ctx.state.loading = true
      http.get(`/orders/Download/${payload}`, { responseType: 'blob', })
        .then(res => {
          if (res.status === 200) {
            resolve(res)
            // ctx.dispatch("_getOrder", payload.order_id)
          } else reject(res)
        })
        .catch((err) => reject(err))
        .finally(() => ctx.state.loading = false)
    })
  },

  // async _walletTopup() {
  //   return new Promise((resolve, reject) => { })
  // },
  // async _pay() { // checkout
  //   return new Promise((resolve, reject) => { })
  // },

  // async _tipWriter() {
  //   return new Promise((resolve, reject) => { })
  // },

  async _changeStatus(ctx, payload) {
    return new Promise((resolve, reject) => {
      ctx.state.loading = true;
      payload
        .post(ctx.state.changeStatusUrl)
        .then(res => {
          if (res.status === 200) {
            resolve(res)
            ctx.dispatch("_getOrder", payload.order_id)
          } else reject(res)
        })
        .catch(err => reject(err))
        .finally(() => ctx.state.loading = false)
    })
  },

  async deleteExtras(ctx, payload) {
    return new Promise((resolve, reject) => {
      http.post('/orders/RemoveExtra/', {
        id: payload
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch("_getOrder", ctx.state.order.id)
            resolve(res)
          } else reject(res)
        })
        .catch(err => reject(err))
    })
  }





}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}