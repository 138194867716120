<template>
  <!-- Page header -->
  <div class="page-header page-header-light bg-white shadow rounded">
    <div class="page-header-content header-elements-md-inline">
      <div class="page-title d-flex py-2">
        <h4>
          <i
            class="icon-arrow-left52 mr-2 has-pointer"
            @click="$router.go(-2)"
          ></i>
          <span class="font-weight-semibold text-capitalize">{{
            title || ""
          }}</span>
        </h4>
        <a href="#" class="header-elements-toggle text-default d-md-none"
          ><i class="icon-more"></i
        ></a>
      </div>

      <!-- <div class="header-elements d-none">
              <a href="#" class="btn btn-labeled btn-labeled-right bg-primary">Button <b><i class="icon-menu7"></i></b></a>
            </div> -->
    </div>
  </div>
  <!-- /page header -->
</template>

<script>
export default {
  name: "Header",
  props: {
    title: {
      type: String,
      required: false,
    },
  },
};
</script>

<style>
.has-pointer {
  cursor: pointer;
}
</style>