import Form from 'vform'
import http from '../modules/http.index'

const state = () => ({
    payment_vars: '',
    paymentForm: new Form({
        type: '',
        amount: '',
        reference: '',
        call_back: '',
    }),
    loading: false,
    paymentUrl: `${process.env.VUE_APP_API_BASE_URL}/Checkout/GetUrl/`,
    wallet_entries: [],
    tips: [],
    discounts: {},
    wallet_balance: 0,
    walletEntriesUrl: `/Users/WalletTransactions/`,
    walletBalanceUrl: `/Users/WalletBalance/`,
    tipsUrl: `/Users/WalletBalance/`,

})

const getters = {}
const mutations = {
    setPaymentVars: (state, payload) => state.payment_vars = payload,
    setWalletEntries: (state, payload) => state.wallet_entries = payload,
    setWalletBalance: (state, payload) => state.wallet_balance = payload,
    setTips: (state, payload) => state.tips = payload,
    setPaymentUrl: (state, payload) => state.paymentUrl = payload,
    setDiscounts: (state, payload) => {
        state.discounts = payload ? payload : {}
    }
}
const actions = {
    async _getPaymentUrl(ctx, payload=""){
        return new Promise((resolve, reject) => {
            payload
                .post(ctx.state.paymentUrl)
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.Data) {
                            ctx.commit("setPaymentVars", res.data.Data)
                        }
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => {
                    ctx.commit("setPaymentUrl", `${process.env.VUE_APP_API_BASE_URL}/Checkout/GetUrl/`)
                })
        })
    },

    async _getWalletBalance(ctx) {
        return new Promise((resolve, reject) => {
            http
                .get(ctx.state.walletBalanceUrl)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res)
                        ctx.commit("setWalletBalance", res.data.Data)
                    } else reject(res)
                })
                .catch((err) => reject(err))
        })
    },

    async _getWalletEntries(ctx) {
        ctx.state.loading = true
        return new Promise((resolve, reject) => {
            http
                .get(ctx.state.walletEntriesUrl)
                .then((res) => {
                    if (res.status === 200) {
                        console.log("wallet res", res);
                        resolve(res)
                        ctx.commit("setWalletEntries", res.data.Data)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        }) 
    },

    async _getTipEntries(ctx) {
        ctx.state.loading = true
        return new Promise((resolve, reject) => {
            http
                .get('/Users/Tips/')
                .then((res) => {
                    if (res.status === 200) {
                        console.log("tip res", res);
                        resolve(res)
                        ctx.commit("setTips", res.data.Data)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)

        })
    },


    async _getDiscounts(ctx) {
        ctx.state.loading = true
        return new Promise((resolve, reject) => {
            http
                .get('/Users/Coupons/')
                .then((res) => {
                    if (res.status === 200) {
                        console.log("wallet res", res);
                        resolve(res)
                        ctx.commit("setDiscounts", res.data.Data)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },


    
}

export  default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}