import Axios from "axios";
let token = localStorage.getItem("token");
Axios.defaults.headers.common = {
  Accept: "application/json",
  "X-Requested-With": "XMLHttpRequest",
  "Content-Type": "application/json",
  Authorization: token,
};
const http = Axios.create({
  // eslint-disable-next-line no-undef
  baseURL: `${process.env.VUE_APP_API_BASE_URL}`,
  // timeout: 7000,
  headers: {
    Accept: "application/json",
    // "Content-Type": "application/json",
    Authorization: token,
  },
  
});
export default http;
