import Vue from 'vue'
import moment from 'moment'

Vue.filter('parseDate', (mydate) => {
  return moment(mydate).format("DD-MM-yyyy h:mm a");
})


// Vue.filter('parseUrgency', (description) => {
//     let desc = parseInt(description)
//     let days = desc / 24;
//     var desc_name = ""
//     if (desc < 24){
//       desc_name = `${desc} Hours`
//       if (desc === 1) {
//         desc_name = `${desc} Hour`            
//       }
//     } else {
//       desc_name = `${days} Days` 
//       if (days === 1) {
//         desc_name = `${days} Day`
//       }
//     }
//     return desc_name
// }) 

Vue.filter('parseUrgency', (seconds) => {
  seconds = Number(seconds);
var d = Math.floor(seconds / (3600*24));
var h = Math.floor(seconds % (3600*24) / 3600);
var m = Math.floor(seconds % 3600 / 60);
var s = Math.floor(seconds % 60);

var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
var hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " mins, ") : "";
var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " secs") : "";
return dDisplay + hDisplay + mDisplay + sDisplay;
})