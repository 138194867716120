import http from '../modules/http.index'

const state = () => ({
    orders: [],
    filters: {},
    title: '',
})
const getters = {}
const mutations = {
    setOrders: (state, payload) => {
        state.orders = payload.Data.orders // Data = res.data.Data
        state.filters = payload.Data.filters
        state.title = payload.Message // title
    }
}
const actions = {
    async _getOrders(ctx, payload) {
        return new Promise((resolve, reject) => {
            http
                .get(`/orders/orders/${payload}`) // payload = order status e.g pending, inprogress
                .then((res) => {
                    if (res.status === 200) {
                        ctx.commit("setOrders", res.data)
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
        })
    },

    async _navigate(ctx, payload) {
        return new Promise((resolve, reject) => {
            http
                .get(payload) // payload = order status e.g pending, inprogress
                .then((res) => {
                    if (res.status === 200) {
                        ctx.commit("setOrders", res.data)
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
        })
    }
}


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}