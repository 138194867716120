/* eslint-disable no-useless-catch */
import http from "../modules/http.index";
const state = () => ({
  user: {},
  isAuthenticated: false,
  loginUrl: `${process.env.VUE_APP_API_BASE_URL}/Users/Login/`,
  resetToken: `${process.env.VUE_APP_API_BASE_URL}/Auth/ResetToken/`,
  resetPassword: `${process.env.VUE_APP_API_BASE_URL}/Auth/ResetPassword/`,
  registerUrl: `${process.env.VUE_APP_API_BASE_URL}/Users/Register/`,
  updateUrl: `${process.env.VUE_APP_API_BASE_URL}/Users/Profile/`,
});

const mutations = {
  setAuthStatus: (state, payload) => {
    state.isAuthenticated = payload
  },
  setAuthUser: (state, payload) => {
    if (payload && Object.keys(payload).length > 0) {
      state.user = payload
    }
  }
};


const getters = {
  authUser: state => state.user,
  isAuthenticated: state => state.isAuthenticated,
};

const actions = {
  // login
  async doLogin(ctx, payload) {
    return new Promise((resolve, reject) => {
      payload
        .post(ctx.state.loginUrl)
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem(
              "token",
              `Bearer ${res.data.Data.access_token}`
            );
            resolve(res);
          }
          else reject(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },



  async doRegister(ctx, payload) {
    return new Promise((resolve, reject) => {
      payload
        .post(ctx.state.registerUrl)
        .then((res) => {
          if (res.status === 200) {
            resolve(res);
          }
          else reject(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // update profile 
  async doUpdateProfile(ctx, payload) {
    return new Promise((resolve, reject) => {
      payload.password = ""
      payload.password_confirmation = ""
      payload
        .put(ctx.state.updateUrl)
        .then((res) => {
          if (res.status === 200) {
            ctx.dispatch("doGetAuthUser")
            resolve(res);
          }
          else reject(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // change password
  async doChangePassword(ctx, payload) {
    return new Promise((resolve, reject) => {
      payload
        .put(ctx.state.updateUrl)
        .then((res) => {
          if (res.status === 200) {
            resolve(res);
          }
          else reject(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // send password reset link
  async doSendPasswordResetLink(ctx, payload) {
    return new Promise((resolve, reject) => {
      payload
        .post(ctx.state.resetToken)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // reset password
  async doResetPassword(ctx, payload) {
    return new Promise((resolve, reject) => {
      payload
        .post(ctx.state.resetPassword)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // get auth user
  async doGetAuthUser(ctx) {
    try {
      let res = await http.get("/Users/Profile/");
      await ctx.commit("setAuthUser", res.data.Data);
      await ctx.commit("setAuthStatus", true);
    } catch (err) {
      throw err;
    }
  },
  // logout
  async doLogout(ctx) {
    localStorage.removeItem("token");
    await ctx.commit("setAuthUser", {});
    await ctx.commit("setAuthStatus", false);
    document.location.href = `/${process.env.VUE_APP_INSTALL_DIR}/login`;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
