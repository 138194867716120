<template>
  <div class="is-loading d-flex justify-content-center align-items-center">
    <!-- <img width="60px" :src="loading_gif"  alt="Loading..."> -->
    <!-- 7 -->
    <div class="loader loader--style6" title="5" style="width:60px; height:60px">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="https://www.w3.org/2000/svg"
        xmlns:xlink="https://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="72px"
        height="90px"
        viewBox="0 0 24 30"
        style="enable-background: new 0 0 50 50"
        xml:space="preserve"
      >
        <rect x="0" y="13" width="4" height="5" fill="#333">
          <animate
            attributeName="height"
            attributeType="XML"
            values="5;21;5"
            begin="0s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            attributeType="XML"
            values="13; 5; 13"
            begin="0s"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="10" y="13" width="4" height="5" fill="#333">
          <animate
            attributeName="height"
            attributeType="XML"
            values="5;21;5"
            begin="0.15s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            attributeType="XML"
            values="13; 5; 13"
            begin="0.15s"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="20" y="13" width="4" height="5" fill="#333">
          <animate
            attributeName="height"
            attributeType="XML"
            values="5;21;5"
            begin="0.3s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            attributeType="XML"
            values="13; 5; 13"
            begin="0.3s"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </rect>
      </svg>
    </div>
  </div>
</template>


<script>
// import loading_gif from "../assets/global_assets/images/loading.gif";
export default {
  name: "BaseLoading",
  data() {
    return {
      // loading_gif: loading_gif,
    };
  },
};
</script>


<style lang="scss">
svg path,
svg rect{
  fill: #009688 ;
}
</style>
