<template>
  <div id="app">
    <VuePNotify :options="options"></VuePNotify>
    <div v-if="loader" class="is-loader w-100 h-100">
      <div class="content d-flex justify-content-center align-items-center">
        <img
          src="./assets/global_assets/images/logo_dark.png"
          alt="Loading.."
        />
      </div>
    </div>
    <router-view v-else />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("setups", ["site_settings"]),
  },
  data() {
    return {
      loader: false,
      options: {
        defaultStyle: "success",
        defaultDelay: 5000,
        width: "380px",
        animateConfig: {
          animate: true,
          in_class: "lightSpeedIn",
          out_class: "lightSpeedOut",
        },
      },
    };
  },
  methods: {
    ...mapActions("setups", ["getSiteSettings"]),
  },

  mounted() {
    this.$on("loader:hide", () => {
      this.loader = false;
    });

    this.getSiteSettings().then(() => {
      let tawk_code = this.site_settings.tawk_code;
      let src = `https://embed.tawk.to/${tawk_code}`;
      var Tawk_API = Tawk_API || {},
        Tawk_LoadStart = new Date();
      var Tawk_API = Tawk_API || {},
        Tawk_LoadStart = new Date();
      (function () {
        var s1 = document.createElement("script"),
          s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = src;
        s1.charset = "UTF-8";
        s1.setAttribute("crossorigin", "*");
        s0.parentNode.insertBefore(s1, s0);
      })();
    });
  },
};
</script>

<style src="vue-pnotify/dist/vue-pnotify.css"></style>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Ubuntu:wght@300;400;500;700&display=swap");
html {
  scroll-behavior: smooth;
}
#app {
  font-family: "Lato", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-family: "Ubuntu", Avenir, Helvetica, Arial, sans-serif;
}
// .ui-pnotify{
//   .ui-pnotify-container {
//     padding: 0.6em !important;
//   }

//   .ui-pnotify-title{
//     font-size: 1.4rem !important;
//     font-weight: bold;
//   }
//   .ui-pnotify-text {
//     font-size: 1.1rem !important;
//   }
// }
.is-loader {
  min-height: 100vh;
  min-width: 100vw;
  z-index: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

