import http from '../modules/http.index'


const state = () => ({
    writers: [],
    writerOrders: [],
    loading: false
})


const getters = {}
const mutations = {
    setWriters: (state, payload) => state.writers = payload,
    setWriterOrders: (state, payload) => state.writerOrders = payload
}
const actions = {
    async getWriters(ctx) {
        return new Promise((resolve, reject) => {
            ctx.state.loading = true
            http
                .get(`/Users/Writers/`) // payload = order status e.g pending, inprogress
                .then((res) => {
                    console.log("writers", res);
                    ctx.commit("setWriters", res.data.Data)
                    resolve(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },

    async getWriterOrders(ctx, payload) {
        return new Promise((resolve, reject) => {
            // ctx.state.loading = true
            http
                .post(`/Users/Writerorders/`, {
                    writer_id: payload
                }) // payload = order status e.g pending, inprogress
                .then((res) => {
                    if (res.status === 200) {
                        ctx.commit("setWriterOrders", res.data.Data)
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
            // .finally(() => ctx.state.loading = false)
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}