import http from '../modules/http.index'

const state = () => ({
	wallet_balance: "0.0",
	orderStats: [],
})

const mutations = {
    setWalletBalance: (state, payload) => state.wallet_balance = payload,
    setOrderStats: (state, payload) => state.orderStats = payload
}

const actions = {
	async _getWalletBalance(ctx, payload) {
      return new Promise((resolve, reject) => {
        http
            .get(`/Users/WalletBalance/`)
            .then((res) => {
            	console.log("Wallet balance", res)
                ctx.commit("setWalletBalance", res.data.Data.balance)
                resolve(res)
            })
            .catch((err) => reject(err))
      })  
    },

    async _getOrderStats(ctx, payload) {
      return new Promise((resolve, reject) => {
        http
            .get(`/orders/OrderStats/`)
            .then((res) => {
                console.log("Dashboard stats", res)
                ctx.commit("setOrderStats", res.data.Data)
                resolve(res)
            })
            .catch((err) => reject(err))
      })  
    },
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}