<template>
  <div class="card rounded shadow">
    <div class="card-body">
      <h5 class="card-title text-uppercase">
        Start Earning With Our Referral Program
      </h5>
      <p v-if="user.referral">
        Earn by referring your friends to place orders with our website. For
        every successful referral we will credit both your account with 25
        dollars each. Invite your friends using your referral code. Your
        referral code is
        <strong>{{ user.referral.link || "" }}</strong> . Earn your
        <strong>${{ user.referral.bonus || "" }} dollars</strong> now
      </p>
      <div>
        <a
          :href="`https://twitter.com/share?text=Hello+buddy%2CI+found+this+fabulous+custom+writing+website.+Its+all+about+helping+students+with+their+complex+school+work+as+you+learn+how+to+write+your+own+A+grade+papers.%3Ca+href%3D%27${siteLink}%3Fid%3D16432%3Eregister%3C%2Fa%3E&amp;url=${siteLink}`"
          target="blank"
        >
          <div class="btn m-1" style="background-color: #1da1f3; color: white">
            <i class="fa fa-twitter"></i>
            Invite on Twitter
          </div>
        </a>
        <a
          :href="`https://www.facebook.com/sharer.php?u=${siteLink}`"
          target="blank"
        >
          <div class="btn m-1" style="background-color: #3b5998; color: white">
            <i class="fa fa-facebook"></i>
            Invite on Facebook
          </div>
        </a>
        <a
          :href="`mailto:?Subject=${siteLink} Referral Program&amp;body=Hello buddy%2CI found this fabulous custom writing website. Its all about helping students with their complex school work as you learn how to write your own A grade papers. Check it out ${siteLink}`"
          target="blank"
        >
          <div class="btn m-1" style="background-color: #ff4343; color: white">
            <i class="fa fa-envelope"></i>
            Invite on Email
          </div>
        </a>
        <!-- <a @click="copyToClipboard(&quot;${siteLink}&quot;)">
                    <div class="btn m-1" style="background-color: #ff4343; color: white;">
                      <i class="fa fa-copy"></i>
                      Copy Link
                    </div>
                  </a> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "BaseShare",
  computed: {
    ...mapState("auth", ["user"]),
    siteLink() {
      return this.user.referral.link || {};
    },
  },
};
</script>