import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
let install_dir = process.env.VUE_APP_INSTALL_DIR;
console.log('install_dir', install_dir);
Vue.use(VueRouter);

const routes = [
  {
    path: `/${install_dir || 'orders'}/`,
    component: () => import("../Orders.vue"),
    children: [
      {
        path: "",
        name: "PlaceOrder",
        component: () => import("../views/pages/PlaceOrder2.vue"),
        meta: {
          // guest: true,
          title: "Place Order",
        },
      },
      {
        path: "",
        name: "PlaceOrder",
        component: () => import("../views/pages/PlaceOrder2.vue"),
        meta: {
          // guest: true,
          title: "Place Order",
        },
      },
      {
        path: "ordernow2",
        name: "Order2",
        component: () => import("../views/pages/PlaceOrder.vue"),
        meta: {
          // guest: true,
          title: "Place Order",
        },
      },

      {
        path: "ordernow",
        redirect: { name: 'PlaceOrder' }
      },

      {
        path: "login",
        name: "Login",
        component: () => import("../views/auth/Login.vue"),
        meta: {
          guest: true,
          title: "Login to your account",
        },
      },
      {
        path: "silentlogin",
        name: "SilentLogin",
        component: () => import("../views/auth/SilentLogin.vue"),
        meta: {
          guest: true,
          title: "Silent Login",
        },
      },

      {
        path: "signup",
        name: "Register",
        component: () => import("../views/auth/Register.vue"),
        meta: {
          guest: true,
          title: "Create an account",
        },
      },

      {
        path: "forgot-password",
        name: "ForgotPassword",
        component: () => import("../views/auth/ForgotPassword.vue"),
        meta: {
          guest: true,
          title: "Forgot Password",
        },
      },

      {
        path: "reset-password",
        name: "PasswordReset",
        component: () => import("../views/auth/PasswordReset.vue"),
        meta: {
          guest: true,
          title: "Forgot password?",
        },
      },

      {
        path: "home",
        name: "Home",
        component: Home,
        children: [
          {
            path: "",
            name: "Dashboard",
            component: () => import("../views/pages/Dashboard.vue"),
            meta: {
              title: "Dashboard",
              requireAuth: true,
            },
          },
          {
            path: "profile",
            name: "Profile",
            component: () => import("../views/pages/Profile.vue"),
            meta: {
              title: "Profile",
              requireAuth: true,
            },
          },
          {
            path: "messages",
            name: "Messages",
            component: () => import("../views/pages/MessagesComponent.vue"),
            meta: {
              title: "Messages",
              requireAuth: true,
            },
          },
          {
            path: "my-writers",
            name: "MyWriters",
            component: () => import("../views/pages/MyWriters.vue"),
            meta: {
              title: "My Writers",
              requireAuth: true,
            },
          },
          {
            path: "wallet",
            name: "Wallet",
            component: () => import("../views/pages/Wallet.vue"),
            meta: {
              title: "Wallet",
              requireAuth: true,
            },
          },

          {
            path: "tips",
            name: "Tips",
            component: () => import("../views/pages/Tips.vue"),
            meta: {
              title: "Tips",
              requireAuth: true,
            },
          },
          {
            path: "earn-from-referrals",
            name: "EarnFromReferrals",
            component: () => import("../views/pages/EarnFromReferrals.vue"),
            meta: {
              title: "Earn From Referrals",
              requireAuth: true,
            },
          },

          {
            path: "my-discounts",
            name: "MyDiscounts",
            component: () => import("../views/pages/MyDiscounts.vue"),
            meta: {
              title: "My Discounts",
              requireAuth: true,
            },
          },

          {
            path: "orders/:status",
            name: "OrderListing",
            component: () => import("../views/pages/OrderListing.vue"),
            meta: {
              required: true,
              title: "Orders"
            },
            props: route => ({ title: `${route.params.status} Orders` })
          },
          {
            path: "order/:id",
            name: "Order",
            component: () => import("../views/pages/Order.vue"),
            meta: {
              title: "Order",
              required: true,
              requireAuth: true,
            }
          },

          {
            path: "order/edit/:id",
            name: "EditOrder",
            component: () => import("../views/pages/EditOrder.vue"),
            meta: {
              title: "Edit Order",
              required: true,
              requireAuth: true,
            }
          },
          {
            path: "checkout/:id",
            name: "payment",
            component: () => import("../views/pages/Checkout.vue"),
            meta: {
              title: "Checkout",
              required: true,
              requireAuth: true,
            }
          },

          {
            path: "bulk-checkout/:ids",
            name: "BulkCheckout",
            component: () => import("../views/pages/BulkCheckout.vue"),
            meta: {
              title: "Checkout",
              required: true,
              requireAuth: true,
            }
          },

        ],
      },

      // errors

      {
        path: "404",
        name: "404",
        meta: {
          requireAuth: false,
          title: "Not Found",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/pages/404.vue"),
      },

      {
        path: "503",
        name: "503",
        meta: {
          requireAuth: false,
          title: "Internal Server Error",
        },
        component: () =>
          import("../views/pages/503.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  linkActiveClass: "just-active",
  linkExactActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    return { x: 0, y: 0 };
  },
});

// validate token
function parseJwt() {
  try {
    let token = localStorage.getItem("token").replace("Bearer ", "");
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      Buffer.from(base64, "base64").toString("ascii")
    );
    return JSON.parse(jsonPayload);
  } catch (e) {
    return false;
  }
}

import store from "../store/index";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}




router.beforeEach((to, from, next) => {


  if (to.matched.length === 0) {
    return next({ path: `/${install_dir || 'orders'}/404` }); // 404 if route not found
  }

  var token = localStorage.getItem("token");
  const jwtPayload = parseJwt();

  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (token && jwtPayload && jwtPayload.expiry > Date.now() / 1000) {
      // if token exists and jwtPayload.exp is not expired
      next();
    } else {
      // token is expired or invalid
      localStorage.removeItem("token");
      next({ path: `/${install_dir || 'orders'}/login`, query: { redirect: to.fullPath } });
    }
  } else if (to.matched.some((record) => record.meta.guest)) { // if route required auth but user is logged in redirect to home
    if (token && jwtPayload && jwtPayload.expiry > Date.now() / 1000) {
      next({ path: `/${install_dir || 'orders'}/home` });
    } else {
      next();
    }
  } else next();


  // change document title dynamically

  let title = to.meta.title
  if (to.name === "OrderListing") {
    title = `${capitalizeFirstLetter(to.params.status)} ${to.meta.title}`
  }

  if (to.params.id) {
    title = `${to.meta.title} ${to.params.id}`
  }


  let domain = (new URL(document.location.href));
  domain = domain.hostname.replace('www.', '');
  document.title = `${domain || process.env.VUE_APP_NAME} - ${title}`;
  document.body.classList.remove("sidebar-mobile-main")

  store.dispatch("auth/doGetAuthUser");
  store.dispatch("setups/getSiteSettings");

});



export default router;
