import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth.store";
import setups from "./setups.store";
import sidebar from "./sidebar.store";
import order from "./order.store";
import orders from "./orders.store";
import messages from "./messages.store";
import writers from "./writers.store";
import payment from "./payment.store";
import dashboard from "./dashboard.store";
// import modules from "./index.module";
Vue.use(Vuex);

// const debug = process.env.NODE_ENV !== 'production'
export default new Vuex.Store({
  state: {
    loading: true,
  },
  mutations: {
    isLoading: (state, payload) => state.loading = payload,
  },
  modules: {
    auth,
    setups,
    sidebar,
    order,
    orders,
    messages,
    writers,
    payment,
    dashboard
  },
  // modules: modules,
  strict: false
});
