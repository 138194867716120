<template>
    <div>
        <base-loading  v-if="!site_settings.site_key"></base-loading>
        <div v-else class="form-group">
            <vue-recaptcha :sitekey="site_settings.site_key" :loadRecaptchaScript="true"></vue-recaptcha>
        </div>
    </div>
</template>

<script>
    import VueRecaptcha from 'vue-recaptcha';
    import { mapState } from 'vuex'
    export default {
        name: "Captcha",
        components: {
            VueRecaptcha
        },
        computed: {
            ...mapState("setups", ["site_settings"])
        }
    };
</script>